import React from 'react';
import { Route } from 'react-router-dom';

import 'smartmenu/models';
import { RestSetup } from 'synergy/setups';
import BaseView from 'smartmenu/components/views/BaseView';

import 'smartmenu/styles/custom.scss';

export default function App() {
  return (
    <RestSetup>
      <Route path="/" component={BaseView} />
    </RestSetup>
  );
}
