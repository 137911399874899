import { ORM } from 'redux-orm';

import Input from './Input';
import Router from './Router';
import Form from './Form';
import Notify from './Notify';
import Filter from './Filter';
import Loader from './Loader';
import User from './User';

const orm = new ORM({
  stateSelector: state => state.orm
});

orm.getSession = store => {
  return orm.session(store.getState().orm);
};

orm.register(Input);
orm.register(Router);
orm.register(Form);
orm.register(Notify);
orm.register(Filter);
orm.register(Loader);
orm.register(User);

export { orm, Input, Router, Form, Notify, Filter, Loader, User };
