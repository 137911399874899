import { orm } from 'smartmenu/models';
import { createSelector } from 'redux-orm';

export const all = createSelector(
  orm,
  session => {
    return session.Restaurant._filter('filter-search-restaurant').toRefArray();
  }
);

export const searchResults = createSelector(
  orm,
  session => {
    return session.Restaurant._filter('filter-search-results').toRefArray();
  }
);

export const favourites = createSelector(
  orm,
  session => {
    return session.Restaurant._filter('filter-search-restaurant')
      .filter({ isFavourite: true })
      .toRefArray();
  }
);

export const searchInput = createSelector(
  orm,
  session => {
    try {
      const qs = session.Input.get({ inputId: 'input-search-restaurant' });
      return qs.ref.value;
    } catch {
      return '';
    }
  }
);
