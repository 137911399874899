import React from 'react';
import { Card, Button } from 'react-bootstrap';

// import { useActions } from 'synergy/hooks';
// import ActionCreators from 'smartmenu/actions';

export default function SalesChannelCard({ salesChannel }) {
  //   const actions = useActions(ActionCreators);

  return (
    <Card
      key={salesChannel.id}
      className="shadow"
      style={{
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateColumns: '1fr 3fr'
      }}
    >
      {salesChannel.image ? (
        <Card.Img
          as="div"
          variant="top"
          style={{
            backgroundImage: `url(${salesChannel.image})`
          }}
        />
      ) : (
        <div className="card-img-container">
          <Card.Img
            variant="top"
            src="/img/bg_black_286x180.png"
            alt="placeholder"
          />
          <div className="card-img-title-container">
            <h1>{salesChannel.name}</h1>
          </div>
        </div>
      )}
      <Card.Body>
        <Card.Title style={{ display: 'grid', gridAutoFlow: 'column' }}>
          <span>
            <div className="sales-channel-title">{salesChannel.name}</div>
            {salesChannel.description}
          </span>
        </Card.Title>
        <Button href={salesChannel.url} target="_blank" variant="primary">
          Tilaa tästä
        </Button>
      </Card.Body>
    </Card>
  );
}
