import React from 'react';

import { useSelector } from 'synergy/hooks';
import Select from 'smartmenu/selectors';

import SalesChannelsList from '../blocks/SalesChannelsList';

export default function SalesChannelsView() {
  const salesChannels = useSelector(Select.SalesChannel.all);

  return <SalesChannelsList salesChannels={salesChannels} />;
}
