import { Model } from 'redux-orm';

class BaseModel extends Model {
  static modelName = 'Base';
  static get modelNameUpper() {
    return this.modelName.toUpperCase();
  }
  static get options() {
    return {
      idAttribute: 'id'
    };
  }

  toString() {
    return `${this.modelName}: ${this.id}`;
  }

  static sync(array) {
    try {
      array.forEach(object => {
        if (!this.idExists(object[this.options.idAttribute])) {
          this.create(object);
        } else {
          this.withId(object[this.options.idAttribute]).update(object);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  static updateOrCreate(object) {
    try {
      if (!this.idExists(object[this.options.idAttribute])) {
        this.create(object);
      } else {
        this.withId(object[this.options.idAttribute]).update(object);
      }
    } catch (error) {
      console.error(error);
    }
  }

  static _update(object) {
    try {
      if (this.idExists(object[this.options.idAttribute])) {
        this.withId(object[this.options.idAttribute]).update(object);
      }
    } catch (error) {
      console.error(error);
    }
  }

  static _delete(object, { silent }) {
    try {
      this.withId(object[this.options.idAttribute]).delete();
    } catch (error) {
      if (!silent) console.error(error);
    }
  }
}

export default BaseModel;
