import { attr } from 'redux-orm';
import InternalBaseModel from './InternalBaseModel';

export default class Input extends InternalBaseModel {
  static modelName = 'Input';
  static fields = {
    inputId: attr()
  };
  static get options() {
    return {
      idAttribute: 'inputId'
    };
  }
}
