import * as form from './form';
import * as filter from './filter';
import * as router from './router';
import * as input from './input';

export const ActionCreators = {
  ...form,
  ...filter,
  ...router,
  ...input
};

export default ActionCreators;
