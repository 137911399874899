import { attr } from 'redux-orm';
import InternalBaseModel from './InternalBaseModel';

export default class Loader extends InternalBaseModel {
  static modelName = 'Loader';
  static fields = {
    id: attr(),
    model: attr()
  };
}
