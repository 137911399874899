import React, { Fragment } from 'react';
import { For } from 'react-extras';
import SalesChannelCard from '../snippets/SalesChannelCard';

import Grid from '../snippets/Grid';

export default function SalesChannelsList({ salesChannels }) {
  return (
    <Fragment>
      <Grid gridRowGap="10px" padding="10px">
        <For
          of={salesChannels || []}
          render={(o, i) => {
            return <SalesChannelCard salesChannel={o} key={i} />;
          }}
        />
      </Grid>
    </Fragment>
  );
}
