import { SalesChannel } from 'smartmenu/models';
const { GET_SUCCESS, PUT_SUCCESS } = SalesChannel.types;

export function getSalesChannels() {
  return {
    type: GET_SUCCESS,
    payload: [
      {
        id: 1,
        image: '/img/sales_channels1.jpg',
        name: 'Vietävän hyvää',
        description: 'Tänään',
        url:
          'https://antell.smartmenu.fi/restaurant/Antell-OP%20Galleria/T%C3%A4n%C3%A4%C3%A4n/'
      },
      {
        id: 2,
        image: 'img/sales_channels2.jpg',
        name: 'Vietävän hyvää',
        description: 'Ennakkoon tilattavat',
        url:
          'https://antell.smartmenu.fi/restaurant/Antell-OP%20Galleria/ennakkoon-tilattavat/'
      },
      {
        id: 3,
        image: '/img/sales_channels3.jpg',
        name: 'Vietävän hyvää',
        description: 'Sesonkituotteet',
        url:
          'https://antell.smartmenu.fi/restaurant/Antell-OP%20Galleria/Sesonkituotteet/'
      }
    ]
  };
}

export function putSalesChannel(data) {
  return {
    type: PUT_SUCCESS,
    payload: data
  };
}
