import { Form } from 'synergy/models';
const { UPDATE_OR_CREATE, DELETE } = Form.types;

export function setForm(data) {
  return {
    type: UPDATE_OR_CREATE,
    payload: data
  };
}

export function deleteForm(data) {
  return {
    type: DELETE,
    payload: data
  };
}
