import { useActions, useDeepCompareEffect } from 'synergy/hooks';
import ActionCreators from 'synergy/actions';

export function InputController() {
  const actions = useActions(ActionCreators);
  useDeepCompareEffect(() => {
    const input = document.querySelector('input');
    if (input) {
      input.addEventListener('input', e => {
        actions.setInput({ inputId: e.target.id, value: e.target.value });
      });
    }
  }, []);
  return null;
}

export default InputController;
