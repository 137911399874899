import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient from 'apollo-boost';

import { store, persistor } from 'synergy/store';
import { API_URL } from 'config';

import InputController from 'synergy/controllers/InputController';

export const client = new ApolloClient({
  uri: `${API_URL}/graphql`
});

export function ApolloSetup({ children }) {
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router history={createBrowserHistory()}>{children}</Router>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  );
}

export function RestSetup({ children }) {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={createBrowserHistory()}>
          <InputController />
          {children}
        </Router>
      </PersistGate>
    </Provider>
  );
}
