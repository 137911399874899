import { Filter } from 'synergy/models';

const { UPDATE_OR_CREATE, DELETE } = Filter.types;

export function setFilter(payload) {
  return {
    type: UPDATE_OR_CREATE,
    payload
  };
}

export function deleteFilter(payload) {
  return {
    type: DELETE,
    payload
  };
}
