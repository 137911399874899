import { attr } from 'redux-orm';
import InternalBaseModel from './InternalBaseModel';

export default class Router extends InternalBaseModel {
  static modelName = 'Router';
  static fields = {
    routerId: attr()
  };
  static get options() {
    return {
      idAttribute: 'routerId'
    };
  }
}
