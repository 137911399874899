import React from 'react';
import Grid from 'smartmenu/components/snippets/Grid';

export default function TitleContainer() {
  return (
    <div className="search-container">
      <img
        src="/img/kierrepulla.png"
        alt="kierrepulla"
        className="kierrepulla"
      ></img>
      <img src="/img/smoothie.png" alt="smoothie" className="smoothie"></img>
      <img src="/img/croissant.png" alt="croissant" className="croissant"></img>
      <Grid padding="30px 15px">
        <h1 className="main-title">
          Antell
          <br /> Galleria
        </h1>
      </Grid>
    </div>
  );
}
