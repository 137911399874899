import React from 'react';
import PropTypes from 'prop-types';

export default function Grid(props) {
  return <div style={{ display: 'grid', ...props }}>{props.children}</div>;
}

Grid.propTypes = {
  gridArea: PropTypes.string,
  gridAutoColumns: PropTypes.string,
  gridAutoFlow: PropTypes.string,
  gridAutoRows: PropTypes.string,
  gridColumn: PropTypes.string,
  gridColumnEnd: PropTypes.string,
  gridColumnStart: PropTypes.string,
  gridRow: PropTypes.string,
  gridRowEnd: PropTypes.string,
  gridRowStart: PropTypes.string,
  gridTemplate: PropTypes.string,
  gridTemplateAreas: PropTypes.string,
  gridTemplateColumns: PropTypes.string,
  gridTemplateRows: PropTypes.string,
  gridGap: PropTypes.string,
  gridColumnGap: PropTypes.string,
  gridRowGap: PropTypes.string
};
