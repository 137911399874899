import { attr } from 'redux-orm';
import InternalBaseModel from './InternalBaseModel';

export default class Notify extends InternalBaseModel {
  static modelName = 'Notify';
  static fields = {
    notifyId: attr(),
    message: attr()
  };
  static get options() {
    return {
      idAttribute: 'notifyId'
    };
  }
}
