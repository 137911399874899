import { attr } from 'redux-orm';
import InternalBaseModel from './InternalBaseModel';

export default class User extends InternalBaseModel {}
User.modelName = 'User';

User.fields = {
  id: attr(),
  username: attr()
};
