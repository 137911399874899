import { persistStore } from 'redux-persist';
import { createStore, applyMiddleware, compose } from 'redux';

import rest from 'synergy/middleware/rest';
import apollo from 'synergy/middleware/apollo';
import rootReducer from 'synergy/reducers';

// Redux Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [rest, apollo];

function configureStore() {
  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middleware))
  );
  const persistor = persistStore(store);
  return { persistor, store };
}

export const { persistor, store } = configureStore();
