import BaseModel from './BaseModel';

class ApiBaseModel extends BaseModel {
  static modelName = 'Base';
  static get types() {
    return {
      GET_REQUEST: `${this.modelNameUpper}:GET_REQUEST`,
      GET_SUCCESS: `${this.modelNameUpper}:GET_SUCCESS`,
      GET_ERROR: `${this.modelNameUpper}:GET_ERROR`,

      POST_REQUEST: `${this.modelNameUpper}:POST_REQUEST`,
      POST_SUCCESS: `${this.modelNameUpper}:POST_SUCCESS`,
      POST_ERROR: `${this.modelNameUpper}:POST_ERROR`,

      PUT_REQUEST: `${this.modelNameUpper}:PUT_REQUEST`,
      PUT_SUCCESS: `${this.modelNameUpper}:PUT_SUCCESS`,
      PUT_ERROR: `${this.modelNameUpper}:PUT_ERROR`,

      DELETE_REQUEST: `${this.modelNameUpper}:DELETE_REQUEST`,
      DELETE_SUCCESS: `${this.modelNameUpper}:DELETE_SUCCESS`,
      DELETE_ERROR: `${this.modelNameUpper}:DELETE_ERROR`
    };
  }

  static _filter(filterId) {
    let filter;
    try {
      filter = this.session.Filter.get({ filterId });
      const value = filter.value || filter.input.value;
      return this.filter(o => {
        return filter.fields.filter(field => {
          const match = o[field].toLowerCase().includes(value.toLowerCase());
          return !!match;
        }).length;
      });
    } catch {
      return this.all();
    }
  }
}

export default ApiBaseModel;
