import BaseModel from './BaseModel';

class InternalBaseModel extends BaseModel {
  static modelName = 'InternalBaseModel';
  static get options() {
    return {};
  }
  static get types() {
    return {
      LOG: `${this.modelNameUpper}:LOG`,
      CLEAR: `${this.modelNameUpper}:CLEAR`,
      SYNC: `${this.modelNameUpper}:SYNC`,
      CREATE: `${this.modelNameUpper}:CREATE`,
      UPDATE: `${this.modelNameUpper}:UPDATE`,
      UPDATE_OR_CREATE: `${this.modelNameUpper}:UPDATE_OR_CREATE`,
      DELETE: `${this.modelNameUpper}:DELETE`
    };
  }
}

export default InternalBaseModel;
