import { persistCombineReducers, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { PERSIST_WHITELIST_MODELS } from 'config';
import ormReducer from './ormReducer';

const config = {
  key: 'root',
  storage: storage,
  transforms: [],
  blacklist: ['orm'],
  debug: false
};

const ormPersistConfig = {
  key: 'orm',
  storage: storage,
  // Persist orm models
  whitelist: PERSIST_WHITELIST_MODELS
};

export default persistCombineReducers(config, {
  orm: persistReducer(ormPersistConfig, ormReducer)
});
