import { attr } from 'redux-orm';
import ApiBaseModel from 'synergy/models/ApiBaseModel';

export default class SalesChannel extends ApiBaseModel {
  static modelName = 'SalesChannel';
  static fields = {
    id: attr(),
    name: attr(),
    url: attr()
  };
}
