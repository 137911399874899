import { orm } from 'synergy/models';

function ormReducer(dbState, action) {
  const session = orm.session(dbState);
  const { Model, types } = resolveModel(action, session);
  if (!Model.options) return session.state; // Skip non-orm actions

  const payload = action.payload;
  const idAttribute = Model.options.idAttribute;

  // TODO: Handle posted object deletion after success

  switch (action.type) {
    case types.LOG:
      console.log(action);
      break;

    case types.CLEAR:
      Model.all().delete();
      break;

    case types.SYNC:
      Model.sync(payload);
      break;

    case types.CREATE:
      Model.create(payload);
      break;

    case types.UPDATE:
      Model._update(payload);
      break;

    case types.UPDATE_OR_CREATE:
      Model.updateOrCreate(payload);
      break;

    case types.DELETE:
      Model._delete(payload);
      break;

    case types.GET_REQUEST:
      session.Loader.create({ model: Model.modelName });
      break;

    case types.GET_SUCCESS:
      if (Array.isArray(payload)) {
        Model.sync(payload);
      } else {
        Model.updateOrCreate(payload);
      }
      const loader = session.Loader.filter({ model: Model.modelName }).first();
      session.Loader._delete(loader, { silent: true });
      break;

    case types.POST_REQUEST:
      Model.updateOrCreate({
        [idAttribute]: Model.guid(),
        ...payload
      });
      break;

    case types.POST_SUCCESS:
      Model.updateOrCreate(payload);
      session.Notify.create({
        notifyId: 'success',
        message: `Tallennus onnistui.`
      });
      break;

    case types.PUT_REQUEST:
      Model.updateOrCreate({
        [idAttribute]: Model.guid(),
        ...payload
      });
      break;

    case types.PUT_SUCCESS:
      Model.updateOrCreate(payload);
      session.Notify.create({
        notifyId: 'success',
        message: `Tallennus onnistui.`
      });
      break;

    case types.ERROR:
      session.Notify.create({ notifyId: 'error', message: `Virhe tapahtui.` });
      break;

    default:
      break;
  }

  return session.state;
}

export default ormReducer;

export function resolveModel(action, session) {
  const modelName = Object.keys(session.state).filter(
    o => o.toUpperCase() === action.type.split(':')[0]
  );
  const Model = session[modelName];
  return Model ? { Model, types: Model.types } : { Model: {}, types: {} };
}
