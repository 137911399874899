import { attr, fk } from 'redux-orm';
import InternalBaseModel from './InternalBaseModel';

export default class Filter extends InternalBaseModel {
  static modelName = 'Filter';
  static fields = {
    filterId: attr(),
    value: attr(),
    input: fk('Input', 'filters')
  };
  static get options() {
    return {
      idAttribute: 'filterId'
    };
  }
}
