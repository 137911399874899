import React from 'react';
// import { Route, Redirect } from 'react-router-dom';
// import { Nav } from 'react-bootstrap';

import { useActions, useDeepCompareEffect } from 'synergy/hooks';
import SmartMenuActionCreators from 'smartmenu/actions';

// import RestaurantsView from './RestaurantsView';
// import FavouritesView from './FavouritesView';
// import RestaurantSearch from 'smartmenu/components/blocks/RestaurantSearch';

import SalesChannelsView from './SalesChannelsView';
import TitleContainer from '../blocks/TitleContainer';

export default function BaseView() {
  const actions = useActions(SmartMenuActionCreators);

  useDeepCompareEffect(() => {
    // actions.getRestaurants();
    actions.getSalesChannels();
    setInterval(() => {
      actions.getSalesChannels();
    }, 5000);
  }, []);

  // function to(to) {
  //   return {
  //     href: to,
  //     onClick: e => {
  //       e.preventDefault();
  //       props.history.push(to);
  //     }
  //   };
  // }

  return (
    <div className="background">
      <div className="body-container">
        {/* <RestaurantSearch /> */}
        <TitleContainer />
        <SalesChannelsView />
        {/* <Nav fill variant="tabs" defaultActiveKey="/restaurants">
        <Nav.Item>
          <Nav.Link {...to('/restaurants')}>Ravintolat</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link {...to('/favourites')}>Suosikit</Nav.Link>
        </Nav.Item>
      </Nav>
      <Route path="/" exact>
        <Redirect to="/restaurants" />
      </Route>
      <Route path="/restaurants" exact component={RestaurantsView} />
      <Route path="/favourites" exact component={FavouritesView} /> */}
      </div>
    </div>
  );
}
