import { client } from 'synergy/setups';

export const QUERY = 'query';
export const MUTATE = 'mutate';

export const GQL = 'Call GQL';

export default store => dispatch => action => {
  const callGQL = action[GQL];

  if (!callGQL) {
    return dispatch(action);
  }

  // Using method
  if (
    !callGQL.method ||
    (callGQL.method !== QUERY && callGQL.method !== MUTATE)
  ) {
    throw new Error('Missing GraphQL method');
  }

  // Using three types: request, success and fail
  if (!callGQL.types || callGQL.types.length !== 3) {
    throw new Error('Invalid action types');
  }

  // Data merge
  const actionWith = data => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[GQL];
    return finalAction;
  };

  const [requestType, successType, failType] = callGQL.types;

  // Dispatch action
  const requestAction = actionWith({
    type: requestType
  });
  dispatch(requestAction);

  // The actual GraphQL query
  return client[callGQL.method]({
    query: callGQL.query,
    variables: callGQL.variables,
    fetchPolicy: 'no-cache'
  })
    .then(response => response.data)
    .then(data => {
      dispatch(
        actionWith({
          type: successType,
          payload: data
        })
      );
    })
    .catch(error => {
      if (!error.response) {
        console.error(error);
      } else {
        dispatch(
          actionWith({
            type: failType,
            status: error.response.status,
            payload: error.response.data
          })
        );
      }
    });
};
